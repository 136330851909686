import Vue from 'vue'

// Make sure to pick a unique name for the flag
// so it won't conflict with any other mixin.
if (!Vue.__form_mixin__) {
  Vue.__form_mixin__ = true
  Vue.mixin({
    methods: {
      // building form data
      buildFormData(formData, data, parentKey = "") {
        if (
          data &&
          typeof data === "object" &&
          !(data instanceof Date) &&
          !(data instanceof File)
        ) {
          Object.keys(data).forEach(
            function(key) {
              this.buildFormData(
                formData,
                data[key],
                parentKey ? `${parentKey}[${key}]` : key
              );
            }.bind(this)
          );
        } else {
          const value = data == null ? "" : data;
  
          formData.append(parentKey, value);
        }
      },
    }
  })
}
