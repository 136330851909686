<template>
  <div
    class="z-50 fixed flex flex-col top-0 right-0 mt-5 mx-auto lg:mr-5 bg-white h-40 w-full lg:w-1/6 max-w-screen lg:max-w-[250px]"
    :style="'top: ' + offset + 'px'"
  >
    <div
      class="w-full p-4 bg-yellow"
      :class="{ 
        'bg-green': variant=== 'success',
        'bg-red-light': variant=== 'success'
      }"
    >
      <h1 class="text-white">
        {{ title }}
      </h1>
    </div>
    <div class="w-full p-4">
      <p>{{ message }}</p>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    title: {
      required: true,
      type: String
    },
    message: {
      required: true,
      type: String
    },
    variant: {
      type: String,
    },
    offset: {
      type: Number
    }
  }
}
</script>