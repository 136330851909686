import Vue from 'vue'

// Make sure to pick a unique name for the flag
// so it won't conflict with any other mixin.
if (!Vue.__user_mixin__) {
  Vue.__user_mixin__ = true
  Vue.mixin({
    scrollToTop: true,
    methods: {
      setUser (user) {
        this.$store.commit('user/setUser', user)
      },
      isSameUser(id){
        let user = this.user()
        return user && user.id === id
      },
      user() {
        return this.$store.getters["auth/user"];
      },
      loggedIn() {
        return this.$store.getters["auth/loggedIn"];
      },
      isAdmin () {
        let user = this.user()
        return user && user.role === "admin"
      },
      hasPermission(permission) {
        let user = this.user()
        if (user && user.permissions) {
          const hasPermission = user.permissions.findIndex(item => {
            return permission === item;
          });
          return hasPermission !== -1;
        }
        return false;
      }
    }
  })
}
