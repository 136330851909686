import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _784b65f6 = () => interopDefault(import('../pages/belepes/index.vue' /* webpackChunkName: "pages/belepes/index" */))
const _fa72c2ec = () => interopDefault(import('../pages/elfelejtett-jelszo/index.vue' /* webpackChunkName: "pages/elfelejtett-jelszo/index" */))
const _59b464ff = () => interopDefault(import('../pages/fiokom/index.vue' /* webpackChunkName: "pages/fiokom/index" */))
const _b991ed9c = () => interopDefault(import('../pages/folknaptar/index.vue' /* webpackChunkName: "pages/folknaptar/index" */))
const _5c65eed2 = () => interopDefault(import('../pages/folkszemle/index.vue' /* webpackChunkName: "pages/folkszemle/index" */))
const _0ee1cff5 = () => interopDefault(import('../pages/forum/index.vue' /* webpackChunkName: "pages/forum/index" */))
const _090d556b = () => interopDefault(import('../pages/galeria/index.vue' /* webpackChunkName: "pages/galeria/index" */))
const _417819d8 = () => interopDefault(import('../pages/radio-stream/index.vue' /* webpackChunkName: "pages/radio-stream/index" */))
const _53447778 = () => interopDefault(import('../pages/regisztracio/index.vue' /* webpackChunkName: "pages/regisztracio/index" */))
const _2a82cd2b = () => interopDefault(import('../pages/tallozo/index.vue' /* webpackChunkName: "pages/tallozo/index" */))
const _51181471 = () => interopDefault(import('../pages/tamogatas/index.vue' /* webpackChunkName: "pages/tamogatas/index" */))
const _46931b1f = () => interopDefault(import('../pages/admin/albumok/index.vue' /* webpackChunkName: "pages/admin/albumok/index" */))
const _4916a44b = () => interopDefault(import('../pages/admin/beallitasok/index.vue' /* webpackChunkName: "pages/admin/beallitasok/index" */))
const _6cdf2679 = () => interopDefault(import('../pages/admin/dalszovegek/index.vue' /* webpackChunkName: "pages/admin/dalszovegek/index" */))
const _116ffd7b = () => interopDefault(import('../pages/admin/eloadok/index.vue' /* webpackChunkName: "pages/admin/eloadok/index" */))
const _3809bf94 = () => interopDefault(import('../pages/admin/esemenyek/index.vue' /* webpackChunkName: "pages/admin/esemenyek/index" */))
const _15be022a = () => interopDefault(import('../pages/admin/felhasznalok/index.vue' /* webpackChunkName: "pages/admin/felhasznalok/index" */))
const _4c874cf2 = () => interopDefault(import('../pages/admin/felvetelek/index.vue' /* webpackChunkName: "pages/admin/felvetelek/index" */))
const _f98ec75c = () => interopDefault(import('../pages/admin/folkszemle/index.vue' /* webpackChunkName: "pages/admin/folkszemle/index" */))
const _5778b275 = () => interopDefault(import('../pages/admin/forum/index.vue' /* webpackChunkName: "pages/admin/forum/index" */))
const _64064190 = () => interopDefault(import('../pages/admin/oldalak/index.vue' /* webpackChunkName: "pages/admin/oldalak/index" */))
const _14281e62 = () => interopDefault(import('../pages/admin/radio/index.vue' /* webpackChunkName: "pages/admin/radio/index" */))
const _ae29e0aa = () => interopDefault(import('../pages/admin/tallozo/index.vue' /* webpackChunkName: "pages/admin/tallozo/index" */))
const _41baf766 = () => interopDefault(import('../pages/admin/tamogatasok/index.vue' /* webpackChunkName: "pages/admin/tamogatasok/index" */))
const _7ea63ca6 = () => interopDefault(import('../pages/admin/zeneialbumok/index.vue' /* webpackChunkName: "pages/admin/zeneialbumok/index" */))
const _26a35ea6 = () => interopDefault(import('../pages/esemeny-emlekezteto/leiratkozas.vue' /* webpackChunkName: "pages/esemeny-emlekezteto/leiratkozas" */))
const _778ad11c = () => interopDefault(import('../pages/fiokom/albumaim/index.vue' /* webpackChunkName: "pages/fiokom/albumaim/index" */))
const _a0344556 = () => interopDefault(import('../pages/fiokom/esemenyeim/index.vue' /* webpackChunkName: "pages/fiokom/esemenyeim/index" */))
const _12195e80 = () => interopDefault(import('../pages/fiokom/profilom/index.vue' /* webpackChunkName: "pages/fiokom/profilom/index" */))
const _695a52bb = () => interopDefault(import('../pages/folknaptar/naptar/index.vue' /* webpackChunkName: "pages/folknaptar/naptar/index" */))
const _32580d4d = () => interopDefault(import('../pages/folkszemle/idezetgyujtemeny/index.vue' /* webpackChunkName: "pages/folkszemle/idezetgyujtemeny/index" */))
const _681a4f6c = () => interopDefault(import('../pages/folkszemle/tudasproba/index.vue' /* webpackChunkName: "pages/folkszemle/tudasproba/index" */))
const _cdad3fd4 = () => interopDefault(import('../pages/hirlevel/leiratkozas.vue' /* webpackChunkName: "pages/hirlevel/leiratkozas" */))
const _5bacd402 = () => interopDefault(import('../pages/mediatar/albumok/index.vue' /* webpackChunkName: "pages/mediatar/albumok/index" */))
const _00fd3159 = () => interopDefault(import('../pages/mediatar/dalszovegek/index.vue' /* webpackChunkName: "pages/mediatar/dalszovegek/index" */))
const _537d1ab2 = () => interopDefault(import('../pages/mediatar/felvetelek/index.vue' /* webpackChunkName: "pages/mediatar/felvetelek/index" */))
const _855da4e8 = () => interopDefault(import('../pages/radio/musorujsag/index.vue' /* webpackChunkName: "pages/radio/musorujsag/index" */))
const _2899ce01 = () => interopDefault(import('../pages/tamogatas/sikeres-tranzakcio/index.vue' /* webpackChunkName: "pages/tamogatas/sikeres-tranzakcio/index" */))
const _d3b66cce = () => interopDefault(import('../pages/admin/eroforrasok/eloadasmodok/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/eloadasmodok/index" */))
const _479b551a = () => interopDefault(import('../pages/admin/eroforrasok/etnikumok/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/etnikumok/index" */))
const _0f0d4fa2 = () => interopDefault(import('../pages/admin/eroforrasok/forrasok/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/forrasok/index" */))
const _f3ab5db2 = () => interopDefault(import('../pages/admin/eroforrasok/gyujtok/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/gyujtok/index" */))
const _d6b9688e = () => interopDefault(import('../pages/admin/eroforrasok/jatszhatosag/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/jatszhatosag/index" */))
const _5439a9b5 = () => interopDefault(import('../pages/admin/eroforrasok/kiadok/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/kiadok/index" */))
const _3ef18908 = () => interopDefault(import('../pages/admin/eroforrasok/megyek/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/megyek/index" */))
const _5e187c41 = () => interopDefault(import('../pages/admin/eroforrasok/mufajok/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/mufajok/index" */))
const _47e3da1f = () => interopDefault(import('../pages/admin/eroforrasok/szovegforrasok/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/szovegforrasok/index" */))
const _214e015f = () => interopDefault(import('../pages/admin/eroforrasok/tajegysegek/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/tajegysegek/index" */))
const _63ffdb2f = () => interopDefault(import('../pages/admin/eroforrasok/telepulesek/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/telepulesek/index" */))
const _2f5b6bf8 = () => interopDefault(import('../pages/admin/eroforrasok/temak/index.vue' /* webpackChunkName: "pages/admin/eroforrasok/temak/index" */))
const _c51bcc0e = () => interopDefault(import('../pages/admin/esemenyek/listak/index.vue' /* webpackChunkName: "pages/admin/esemenyek/listak/index" */))
const _28860fcd = () => interopDefault(import('../pages/admin/folkszemle/idezetgyujtemeny/index.vue' /* webpackChunkName: "pages/admin/folkszemle/idezetgyujtemeny/index" */))
const _7374baca = () => interopDefault(import('../pages/admin/folkszemle/tudasproba/index.vue' /* webpackChunkName: "pages/admin/folkszemle/tudasproba/index" */))
const _66499db0 = () => interopDefault(import('../pages/admin/radio/szinkron/index.vue' /* webpackChunkName: "pages/admin/radio/szinkron/index" */))
const _1c5c8656 = () => interopDefault(import('../pages/admin/esemenyek/listak/lista/_id.vue' /* webpackChunkName: "pages/admin/esemenyek/listak/lista/_id" */))
const _3984857f = () => interopDefault(import('../pages/admin/folkszemle/idezetgyujtemeny/idezet/_id.vue' /* webpackChunkName: "pages/admin/folkszemle/idezetgyujtemeny/idezet/_id" */))
const _1142649d = () => interopDefault(import('../pages/admin/folkszemle/tudasproba/kerdes/_id.vue' /* webpackChunkName: "pages/admin/folkszemle/tudasproba/kerdes/_id" */))
const _35633254 = () => interopDefault(import('../pages/admin/eloadok/eloado/_id.vue' /* webpackChunkName: "pages/admin/eloadok/eloado/_id" */))
const _52efab26 = () => interopDefault(import('../pages/admin/felhasznalok/felhasznalo/_id.vue' /* webpackChunkName: "pages/admin/felhasznalok/felhasznalo/_id" */))
const _590f78be = () => interopDefault(import('../pages/admin/folkszemle/cikk/_id.vue' /* webpackChunkName: "pages/admin/folkszemle/cikk/_id" */))
const _3a01aa0a = () => interopDefault(import('../pages/admin/oldalak/oldal/_id.vue' /* webpackChunkName: "pages/admin/oldalak/oldal/_id" */))
const _a61534b0 = () => interopDefault(import('../pages/admin/tallozo/cikk/_id.vue' /* webpackChunkName: "pages/admin/tallozo/cikk/_id" */))
const _4525a024 = () => interopDefault(import('../pages/admin/zeneialbumok/album/_id.vue' /* webpackChunkName: "pages/admin/zeneialbumok/album/_id" */))
const _4f5d5ac0 = () => interopDefault(import('../pages/admin/dalszovegek/dalszoveg/_id/_recording.vue' /* webpackChunkName: "pages/admin/dalszovegek/dalszoveg/_id/_recording" */))
const _2caddf6c = () => interopDefault(import('../pages/admin/felvetelek/felvetel/_id/_album.vue' /* webpackChunkName: "pages/admin/felvetelek/felvetel/_id/_album" */))
const _5b5d41da = () => interopDefault(import('../pages/folkszemle/idezetgyujtemeny/idezet/_id/_name.vue' /* webpackChunkName: "pages/folkszemle/idezetgyujtemeny/idezet/_id/_name" */))
const _eb00f8fa = () => interopDefault(import('../pages/admin/forum/_category/index.vue' /* webpackChunkName: "pages/admin/forum/_category/index" */))
const _7e9be78c = () => interopDefault(import('../pages/admin/radio/_id/index.vue' /* webpackChunkName: "pages/admin/radio/_id/index" */))
const _8be4f872 = () => interopDefault(import('../pages/fiokom/album/_id.vue' /* webpackChunkName: "pages/fiokom/album/_id" */))
const _5705f854 = () => interopDefault(import('../pages/radio/musorujsag/_day/index.vue' /* webpackChunkName: "pages/radio/musorujsag/_day/index" */))
const _557ee2a4 = () => interopDefault(import('../pages/tamogatas/sikertelen-tranzakcio/_type.vue' /* webpackChunkName: "pages/tamogatas/sikertelen-tranzakcio/_type" */))
const _43d2d869 = () => interopDefault(import('../pages/admin/forum/_category/_theme.vue' /* webpackChunkName: "pages/admin/forum/_category/_theme" */))
const _4058b602 = () => interopDefault(import('../pages/fiokom/esemeny/_id/_klon.vue' /* webpackChunkName: "pages/fiokom/esemeny/_id/_klon" */))
const _9da25b7a = () => interopDefault(import('../pages/folknaptar/esemeny/_id/_name/index.vue' /* webpackChunkName: "pages/folknaptar/esemeny/_id/_name/index" */))
const _c960be40 = () => interopDefault(import('../pages/folknaptar/listak/_slug/_year.vue' /* webpackChunkName: "pages/folknaptar/listak/_slug/_year" */))
const _6b35497c = () => interopDefault(import('../pages/folkszemle/cikk/_id/_name.vue' /* webpackChunkName: "pages/folkszemle/cikk/_id/_name" */))
const _512b20f8 = () => interopDefault(import('../pages/forum/tema/_id/_name.vue' /* webpackChunkName: "pages/forum/tema/_id/_name" */))
const _7c08310e = () => interopDefault(import('../pages/galeria/album/_id/_name.vue' /* webpackChunkName: "pages/galeria/album/_id/_name" */))
const _5fc33511 = () => interopDefault(import('../pages/galeria/album/_id/_name/index.vue' /* webpackChunkName: "pages/galeria/album/_id/_name/index" */))
const _55f9d415 = () => interopDefault(import('../pages/galeria/album/_id/_name/_img.vue' /* webpackChunkName: "pages/galeria/album/_id/_name/_img" */))
const _1d14cffc = () => interopDefault(import('../pages/mediatar/albumok/_id/_name.vue' /* webpackChunkName: "pages/mediatar/albumok/_id/_name" */))
const _0bf7f65c = () => interopDefault(import('../pages/mediatar/dalszovegek/_id/_name.vue' /* webpackChunkName: "pages/mediatar/dalszovegek/_id/_name" */))
const _377ee0aa = () => interopDefault(import('../pages/mediatar/felvetelek/_id/_name.vue' /* webpackChunkName: "pages/mediatar/felvetelek/_id/_name" */))
const _5a3a7f43 = () => interopDefault(import('../pages/tallozo/cikk/_id/_name.vue' /* webpackChunkName: "pages/tallozo/cikk/_id/_name" */))
const _ea1c01ac = () => interopDefault(import('../pages/elfelejtett-jelszo/_id/_token/index.vue' /* webpackChunkName: "pages/elfelejtett-jelszo/_id/_token/index" */))
const _77002223 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e039a0a0 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/belepes",
    component: _784b65f6,
    name: "belepes"
  }, {
    path: "/elfelejtett-jelszo",
    component: _fa72c2ec,
    name: "elfelejtett-jelszo"
  }, {
    path: "/fiokom",
    component: _59b464ff,
    name: "fiokom"
  }, {
    path: "/folknaptar",
    component: _b991ed9c,
    name: "folknaptar"
  }, {
    path: "/folkszemle",
    component: _5c65eed2,
    name: "folkszemle"
  }, {
    path: "/forum",
    component: _0ee1cff5,
    name: "forum"
  }, {
    path: "/galeria",
    component: _090d556b,
    name: "galeria"
  }, {
    path: "/radio-stream",
    component: _417819d8,
    name: "radio-stream"
  }, {
    path: "/regisztracio",
    component: _53447778,
    name: "regisztracio"
  }, {
    path: "/tallozo",
    component: _2a82cd2b,
    name: "tallozo"
  }, {
    path: "/tamogatas",
    component: _51181471,
    name: "tamogatas"
  }, {
    path: "/admin/albumok",
    component: _46931b1f,
    name: "admin-albumok"
  }, {
    path: "/admin/beallitasok",
    component: _4916a44b,
    name: "admin-beallitasok"
  }, {
    path: "/admin/dalszovegek",
    component: _6cdf2679,
    name: "admin-dalszovegek"
  }, {
    path: "/admin/eloadok",
    component: _116ffd7b,
    name: "admin-eloadok"
  }, {
    path: "/admin/esemenyek",
    component: _3809bf94,
    name: "admin-esemenyek"
  }, {
    path: "/admin/felhasznalok",
    component: _15be022a,
    name: "admin-felhasznalok"
  }, {
    path: "/admin/felvetelek",
    component: _4c874cf2,
    name: "admin-felvetelek"
  }, {
    path: "/admin/folkszemle",
    component: _f98ec75c,
    name: "admin-folkszemle"
  }, {
    path: "/admin/forum",
    component: _5778b275,
    name: "admin-forum"
  }, {
    path: "/admin/oldalak",
    component: _64064190,
    name: "admin-oldalak"
  }, {
    path: "/admin/radio",
    component: _14281e62,
    name: "admin-radio"
  }, {
    path: "/admin/tallozo",
    component: _ae29e0aa,
    name: "admin-tallozo"
  }, {
    path: "/admin/tamogatasok",
    component: _41baf766,
    name: "admin-tamogatasok"
  }, {
    path: "/admin/zeneialbumok",
    component: _7ea63ca6,
    name: "admin-zeneialbumok"
  }, {
    path: "/esemeny-emlekezteto/leiratkozas",
    component: _26a35ea6,
    name: "esemeny-emlekezteto-leiratkozas"
  }, {
    path: "/fiokom/albumaim",
    component: _778ad11c,
    name: "fiokom-albumaim"
  }, {
    path: "/fiokom/esemenyeim",
    component: _a0344556,
    name: "fiokom-esemenyeim"
  }, {
    path: "/fiokom/profilom",
    component: _12195e80,
    name: "fiokom-profilom"
  }, {
    path: "/folknaptar/naptar",
    component: _695a52bb,
    name: "folknaptar-naptar"
  }, {
    path: "/folkszemle/idezetgyujtemeny",
    component: _32580d4d,
    name: "folkszemle-idezetgyujtemeny"
  }, {
    path: "/folkszemle/tudasproba",
    component: _681a4f6c,
    name: "folkszemle-tudasproba"
  }, {
    path: "/hirlevel/leiratkozas",
    component: _cdad3fd4,
    name: "hirlevel-leiratkozas"
  }, {
    path: "/mediatar/albumok",
    component: _5bacd402,
    name: "mediatar-albumok"
  }, {
    path: "/mediatar/dalszovegek",
    component: _00fd3159,
    name: "mediatar-dalszovegek"
  }, {
    path: "/mediatar/felvetelek",
    component: _537d1ab2,
    name: "mediatar-felvetelek"
  }, {
    path: "/radio/musorujsag",
    component: _855da4e8,
    name: "radio-musorujsag"
  }, {
    path: "/tamogatas/sikeres-tranzakcio",
    component: _2899ce01,
    name: "tamogatas-sikeres-tranzakcio"
  }, {
    path: "/admin/eroforrasok/eloadasmodok",
    component: _d3b66cce,
    name: "admin-eroforrasok-eloadasmodok"
  }, {
    path: "/admin/eroforrasok/etnikumok",
    component: _479b551a,
    name: "admin-eroforrasok-etnikumok"
  }, {
    path: "/admin/eroforrasok/forrasok",
    component: _0f0d4fa2,
    name: "admin-eroforrasok-forrasok"
  }, {
    path: "/admin/eroforrasok/gyujtok",
    component: _f3ab5db2,
    name: "admin-eroforrasok-gyujtok"
  }, {
    path: "/admin/eroforrasok/jatszhatosag",
    component: _d6b9688e,
    name: "admin-eroforrasok-jatszhatosag"
  }, {
    path: "/admin/eroforrasok/kiadok",
    component: _5439a9b5,
    name: "admin-eroforrasok-kiadok"
  }, {
    path: "/admin/eroforrasok/megyek",
    component: _3ef18908,
    name: "admin-eroforrasok-megyek"
  }, {
    path: "/admin/eroforrasok/mufajok",
    component: _5e187c41,
    name: "admin-eroforrasok-mufajok"
  }, {
    path: "/admin/eroforrasok/szovegforrasok",
    component: _47e3da1f,
    name: "admin-eroforrasok-szovegforrasok"
  }, {
    path: "/admin/eroforrasok/tajegysegek",
    component: _214e015f,
    name: "admin-eroforrasok-tajegysegek"
  }, {
    path: "/admin/eroforrasok/telepulesek",
    component: _63ffdb2f,
    name: "admin-eroforrasok-telepulesek"
  }, {
    path: "/admin/eroforrasok/temak",
    component: _2f5b6bf8,
    name: "admin-eroforrasok-temak"
  }, {
    path: "/admin/esemenyek/listak",
    component: _c51bcc0e,
    name: "admin-esemenyek-listak"
  }, {
    path: "/admin/folkszemle/idezetgyujtemeny",
    component: _28860fcd,
    name: "admin-folkszemle-idezetgyujtemeny"
  }, {
    path: "/admin/folkszemle/tudasproba",
    component: _7374baca,
    name: "admin-folkszemle-tudasproba"
  }, {
    path: "/admin/radio/szinkron",
    component: _66499db0,
    name: "admin-radio-szinkron"
  }, {
    path: "/admin/esemenyek/listak/lista/:id?",
    component: _1c5c8656,
    name: "admin-esemenyek-listak-lista-id"
  }, {
    path: "/admin/folkszemle/idezetgyujtemeny/idezet/:id?",
    component: _3984857f,
    name: "admin-folkszemle-idezetgyujtemeny-idezet-id"
  }, {
    path: "/admin/folkszemle/tudasproba/kerdes/:id?",
    component: _1142649d,
    name: "admin-folkszemle-tudasproba-kerdes-id"
  }, {
    path: "/admin/eloadok/eloado/:id?",
    component: _35633254,
    name: "admin-eloadok-eloado-id"
  }, {
    path: "/admin/felhasznalok/felhasznalo/:id?",
    component: _52efab26,
    name: "admin-felhasznalok-felhasznalo-id"
  }, {
    path: "/admin/folkszemle/cikk/:id?",
    component: _590f78be,
    name: "admin-folkszemle-cikk-id"
  }, {
    path: "/admin/oldalak/oldal/:id?",
    component: _3a01aa0a,
    name: "admin-oldalak-oldal-id"
  }, {
    path: "/admin/tallozo/cikk/:id?",
    component: _a61534b0,
    name: "admin-tallozo-cikk-id"
  }, {
    path: "/admin/zeneialbumok/album/:id?",
    component: _4525a024,
    name: "admin-zeneialbumok-album-id"
  }, {
    path: "/admin/dalszovegek/dalszoveg/:id?/:recording?",
    component: _4f5d5ac0,
    name: "admin-dalszovegek-dalszoveg-id-recording"
  }, {
    path: "/admin/felvetelek/felvetel/:id?/:album?",
    component: _2caddf6c,
    name: "admin-felvetelek-felvetel-id-album"
  }, {
    path: "/folkszemle/idezetgyujtemeny/idezet/:id?/:name?",
    component: _5b5d41da,
    name: "folkszemle-idezetgyujtemeny-idezet-id-name"
  }, {
    path: "/admin/forum/:category",
    component: _eb00f8fa,
    name: "admin-forum-category"
  }, {
    path: "/admin/radio/:id",
    component: _7e9be78c,
    name: "admin-radio-id"
  }, {
    path: "/fiokom/album/:id?",
    component: _8be4f872,
    name: "fiokom-album-id"
  }, {
    path: "/radio/musorujsag/:day",
    component: _5705f854,
    name: "radio-musorujsag-day"
  }, {
    path: "/tamogatas/sikertelen-tranzakcio/:type?",
    component: _557ee2a4,
    name: "tamogatas-sikertelen-tranzakcio-type"
  }, {
    path: "/admin/forum/:category/:theme",
    component: _43d2d869,
    name: "admin-forum-category-theme"
  }, {
    path: "/fiokom/esemeny/:id?/:klon?",
    component: _4058b602,
    name: "fiokom-esemeny-id-klon"
  }, {
    path: "/folknaptar/esemeny/:id?/:name",
    component: _9da25b7a,
    name: "folknaptar-esemeny-id-name"
  }, {
    path: "/folknaptar/listak/:slug?/:year?",
    component: _c960be40,
    name: "folknaptar-listak-slug-year"
  }, {
    path: "/folkszemle/cikk/:id?/:name?",
    component: _6b35497c,
    name: "folkszemle-cikk-id-name"
  }, {
    path: "/forum/tema/:id?/:name?",
    component: _512b20f8,
    name: "forum-tema-id-name"
  }, {
    path: "/galeria/album/:id?/:name?",
    component: _7c08310e,
    children: [{
      path: "",
      component: _5fc33511,
      name: "galeria-album-id-name"
    }, {
      path: ":img",
      component: _55f9d415,
      name: "galeria-album-id-name-img"
    }]
  }, {
    path: "/mediatar/albumok/:id/:name?",
    component: _1d14cffc,
    name: "mediatar-albumok-id-name"
  }, {
    path: "/mediatar/dalszovegek/:id/:name?",
    component: _0bf7f65c,
    name: "mediatar-dalszovegek-id-name"
  }, {
    path: "/mediatar/felvetelek/:id/:name?",
    component: _377ee0aa,
    name: "mediatar-felvetelek-id-name"
  }, {
    path: "/tallozo/cikk/:id?/:name?",
    component: _5a3a7f43,
    name: "tallozo-cikk-id-name"
  }, {
    path: "/elfelejtett-jelszo/:id?/:token",
    component: _ea1c01ac,
    name: "elfelejtett-jelszo-id-token"
  }, {
    path: "/",
    component: _77002223,
    name: "index"
  }, {
    path: "/*",
    component: _e039a0a0,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
