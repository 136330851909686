import Vue from 'vue'
import ReToast from '~/plugins/components/recomponents/ReToast.vue'

let ReToastClass = Vue.extend(ReToast)

Vue.prototype.$rxt = (() => {
  let toastComponents = []
  let offsetGap = 10

  return {
    toast: (message, toastObject) => {
      let toastComponent = null
      let offset = offsetGap;

      if(toastComponents.length){
          let lastToast = toastComponents[toastComponents.length - 1]
          offset += lastToast.$el.offsetHeight + lastToast.$el.offsetTop
      }

      toastObject['offset'] = offset
      toastObject['message'] = message
      toastObject['id'] = 
      toastComponent = new ReToastClass({
          propsData: toastObject
      })
      toastComponents.push(toastComponent)
      toastComponent.$mount()
      document.getElementById('__layout').appendChild(toastComponent.$el)

      console.log(toastComponent)
      if(toastObject.autoHideDelay){
        setTimeout(() => {
          toastComponent.$el.remove()
          let index = toastComponents.findIndex((item) => item === toastComponent)
          if(index !== -1){
              toastComponents.splice(index, 1)
          }
          let recalculatedOffset = offsetGap;
          toastComponents.forEach((item, index) => {
              if(index - 1 > 0){
                  recalculatedOffset += toastComponents[index-1].offsetHeight + offsetGap
              }
              item.offset = recalculatedOffset
              item.$forceUpdate()
          })
        }, toastObject.autoHideDelay)
      }
    }
  }
})()
Vue.component('re-toast', ReToast)