const cookieparser = process.server ? require("cookieparser") : undefined;

export const state = () => {
  return {
    provider: "email",
    token: null,
    user: null,
    debug: null
  };
};
export const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setProvider(state, provider) {
    state.provider = provider;
  },
  setUser(state, user) {
    state.user = user;
  },
  setDebug(state, debug) {
    state.debug = debug;
  }
};

export const getters = {
  loggedIn: (state, getters) => {
    return state.user !== null;
  },
  user: (state, getters) => {
    return state.user;
  }
};

export const actions = {
  async init(store, context) {
    let token = null;
    let user = null;
    // console.log(context)
    // console.log(context.req.headers)
    // store.commit('setDebug', context.req.headers)
    if (context.req && context.req.headers.cookie) {
      try {
        const parsed = cookieparser.parse(context.req.headers.cookie);
        token = parsed.__session;

        if (token) {
          context.app.$axios.onRequest(config => {
            config.headers.common.Authorization = "Bearer " + token;
          });
          const result = await context.app.$axios("/users/current");
          user = result.data.data;
        }
      } catch (err) {
        token = null;
      }
    }
    store.commit("setUser", user);
    store.commit("setToken", token);
  }
};
