import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB4B2jB1hd1HvY-b-_T_T1-AGpc-hLF4ZE",
    libraries: "places",
    language: "hu",
    region: "hu"
  }
});


// import VueGoogleMaps from '@fawmi/vue-google-maps'

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: "AIzaSyB4B2jB1hd1HvY-b-_T_T1-AGpc-hLF4ZE",
//     libraries: "places",
//     language: "hu",
//     region: "hu"
//   },
// })