export const state = () => ({
  mode: "list", // the other is 'calendar'
  queryObject: { history: false, limit: 10 }
});

export const mutations = {
  setMode(state, mode) {
    if (state.mode !== mode) {
      state.mode = mode;
      if (
        mode === "my_articles" ||
        mode === "my_gleaner" ||
        mode === "gallery" ||
        mode === "my_forum_themes" ||
        mode === "my_forum_theme_comments"
      ) {
        state.queryObject = { limit: 10 };
      }
    }
  },
  setQueryObject(state, queryObject) {
    if (state.mode === "my_articles" || state.mode === "gallery") {
      delete queryObject.history;
    }

    state.queryObject = queryObject;
  }
};
