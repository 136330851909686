// import Vue from "vue";
// import VuejsClipper from "vuejs-clipper";
// // install
// Vue.use(VuejsClipper, {
//   components: {
//     clipperBasic: "clipper-basic",
//     clipperPreview: "clipper-preview"
//   }
// });


import Vue from 'vue'
import VueRx from 'vue-rx'
// install vue-rx
Vue.use(VueRx)