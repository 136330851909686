// import { cacheAdapterEnhancer } from "axios-extensions"
// import LRUCache from "lru-cache"
// const FIVE_MINUTES = 1000 * 60 * 5

export default function({ $axios, ssrContext, store }) {
  // const defaultCache = process.server && ssrContext.$axCache
  //   ? ssrContext.$axCache
  //   : new LRUCache({ maxAge: FIVE_MINUTES, max: 100 })
  // const defaultCache = new LRUCache({ maxAge: FIVE_MINUTES, max: 100 })

  // $axios.defaults.adapter = cacheAdapterEnhancer(
  //   $axios.defaults.adapter,
  //   { 
  //     enabledByDefault: true,
  //     cacheFlag: "useCache",
  //     defaultCache
  //   }
  // )
  $axios.onRequest(config => {
    if (store.state.auth.token) {
      config.headers.common.Authorization = "Bearer " + store.state.auth.token;
    }
  });
}