import Echo from "laravel-echo";

export default function({ $axios, app, store }) {
  window.io = require("socket.io-client"); // Have this in case you stop running your laravel echo serverif (typeof io !== 'undefined') {
  let url = $axios.defaults.baseURL;
  if (!url.includes("test")) {
    url = "https://socket.folkradio.hu";
  } else {
    url = "https://testsocket.folkradio.hu"; // $axios.defaults.baseURL.replace('https', 'http')// '46.101.137.131'
  }
  window.Echo = new Echo({
    broadcaster: "socket.io",
    host: url
  });

  //lazysizes init
  // document.addEventListener('lazybeforeunveil', function(e){
  //   var bg = e.target.getAttribute('data-bg');
  //   if(bg){
  //       e.target.style.backgroundImage = 'url(' + bg + ')';
  //   }
  // });
}
