export const state = () => ({
  loadedEventTypes: false,
  eventTypes: [],

  loadedArticleTypes: false,
  articleTypes: [],

  loadedGleanerTypes: false,
  gleanerTypes: [],

  loadedQuoteTypes: false,
  quoteTypes: [],

  loadedArticleAuthors: false,
  articleAuthors: [],

  loadedArticleEditors: false,
  articleEditors: [],

  loadedQuoteAuthors: false,
  quoteAuthors: [],

  loadedForumCategories: false,
  forumCategories: [],

  loadedCities: false,
  cities: [],

  loadedCountries: false,
  countries: [],

  loadedCitiesActual: false,
  citiesActual: [],

  loadedCountriesActual: false,
  countriesActual: [],

  nowPlaying: { data: [] },
  isRadioPlaying: false,

  knowledgebaseWindow: {
    right: false,
    question: null
  },
  
  knowledgebase: {
    answered: null,
    goodAnswers: 0,
  },

  playlist: {
    entity: {},
    recordings: { data: [] }
  },
});

export const getters = {
  getNowPlaying: (state, getters) => {
    return state.nowPlaying;
  },
  getKnowledgebaseWindowResult: (state, getters) => {
    return state.knowledgebaseWindow;
  },
  getKnowledgebaseResult: (state, getters) => {
    return state.knowledgebase;
  },
  getPlaylist: (state, getters) => {
    return state.playlist;
  },
  isRadioPlaying: (state, getters) => {
    return state.isRadioPlaying
  }
}

export const actions = {
  async nuxtServerInit(store, context) {
    let responses = await Promise.all([
      this.$axios.get("settings?filter[search]=OPTIONS"),
      // this.$axios.get("radio/nowplaying")
  ])
    let settings = {} 
    responses[0].data.data.forEach(item => {
      settings[item.key] = item
    });

    this.commit('setSettings', settings)
    // this.commit('setNowPlaying', responses[1].data)

    return store.dispatch("auth/init", context);
  },
  loadRadioPlaylist() {
    this.$axios.get(
      "radio/playlists?filter[day]=" + this.$moment().format('YYYY-MM-DD')
    ).then(response => {
      const todaysPlaylist = response.data.data[0];
  
      this.$axios.get(
        "/media/recordings?sort=priority&filter[playlist_id]=" + todaysPlaylist.id + "&fields[recordings]=recordings.id,title,status,album_id,length&fields[album]=music_albums.id,music_albums.title&fields[settlements]=settlements.id,settlements.name&include=album,performers,settlements,lyrics&fields[performers]=performers.id,performers.name&fields[lyrics]=lyrics.id,lyrics.title"
      ).then(response => {
        const recordings = response.data;
    
        this.commit('loadPlaylist', { entity: todaysPlaylist, recordings: recordings})
      });
    })
  },
  async loadEventTypes() {
    if (!this.state.loadedEventTypes) {
      let response = await this.$axios("/v2/types?sort=type&filter[kind]=event")
        this.commit("setEventTypes", response.data.data);
    }
  },
  async loadCities() {
    if (!this.state.loadedCities) {
      let response = await this.$axios("/cities")
      this.commit("setCities", response.data.data);
    }
  },
  async loadCountires() {
    if (!this.state.loadedCountries) {
      let response = await this.$axios("/countries")
      this.commit("setCountries", response.data.data);
    }
  },
  async loadCitiesActual() {
    if (!this.state.loadedCitiesActual) {
      let response = await this.$axios("/cities/actual")
      this.commit("setCitiesActual", response.data.data);
    }
  },
  async loadCountriesActual() {
    if (!this.state.loadedCountriesActual) {
      let response = await this.$axios("/countries/actual")
      this.commit("setCountriesActual", response.data.data);
    }
  },
  async loadArticleTypes() {
    if (!this.state.loadedArticleTypes) {
      let response = await this.$axios("/v2/types?sort=type&filter[kind]=article")
      this.commit("setArticleTypes", response.data.data);
    }
  },
  async loadArticleAuthors() {
    if (!this.state.loadedArticleAuthors) {
      let response = await this.$axios("/authors/author/article")
      this.commit("setArticleAuthors", response.data.data);
    }
  },
  async loadArticleEditors() {
    if (!this.state.loadedArticleEditors) {
      let response = await this.$axios("/authors/editor/article")
      this.commit("setArticleEditors", response.data.data);
    }
  },
  async loadQuoteTypes() {
    if (!this.state.loadedQuoteTypes) {
      let response = await  this.$axios("/v2/types?sort=type&filter[kind]=quote")
      this.commit("setQuoteTypes", response.data.data);
    }
  },
  async loadQuoteAuthors() {
    if (!this.state.loadedQuoteAuthors) {
      let response = await this.$axios("/authors/author/quote")
      this.commit("setQuoteAuthors", response.data.data);
    }
  },
  async loadGleanerTypes() {
    if (!this.state.loadedGleanerTypes) {
      let response = await this.$axios("/v2/types?sort=type&filter[kind]=gleaner")
      this.commit("setGleanerTypes", response.data.data);
    }
  },
  async loadForumCategories() {
    if (!this.state.loadedForumCategories) {
      let response = await this.$axios("/forum/categories")
      this.commit("setForumCategories", response.data.data);
    }
  }
};

export const mutations = {
  setIsRadioPlaying(state, payload){
    state.isRadioPlaying = payload
  },

  resetKnowledgebase(state, payload) {
    state.knowledgebase.answered=0
    state.knowledgebase.goodAnswers=0
  },
  incKnowledgebaseAnswered(state, payload) {
    state.knowledgebase.answered++
  },
  incKnowledgebaseGoodAnswers(state, payload) {
    state.knowledgebase.goodAnswers++
  },

  loadPlaylist(state, payload){
    state.playlist = payload
  },
  setQuestion(state, payload){
    state.knowledgebaseWindow.question = payload.question
    state.knowledgebaseWindow.right = payload.right
  },
  setNowPlaying(state, nowPlaying) {
    state.nowPlaying = nowPlaying;
  },
  setEventTypes(state, list) {
    state.eventTypes = list;
    state.loadedEventTypes = true;
  },
  setArticleTypes(state, list) {
    state.articleTypes = list;
    state.loadedArticleTypes = true;
  },
  setGleanerTypes(state, list) {
    state.gleanerTypes = list;
    state.loadedGleanerTypes = true;
  },
  setQuoteTypes(state, list) {
    state.quoteTypes = list;
    state.loadedQuoteTypes = true;
  },
  setArticleAuthors(state, list) {
    state.articleAuthors = list;
    state.loadedArticleAuthors = true;
  },
  setArticleEditors(state, list) {
    state.articleEditors = list;
    state.loadedArticleEditors = true;
  },
  setQuoteAuthors(state, list) {
    state.quoteAuthors = list;
    state.loadedQuoteAuthors = true;
  },
  setForumCategories(state, list) {
    state.forumCategories = list;
    state.loadedForumCategories = true;
  },
  setCities(state, list) {
    state.cities = list;
    state.loadedCities = true;
  },
  setCountries(state, list) {
    state.countries = list;
    state.loadedCountries = true;
  },
  setCitiesActual(state, list) {
    state.citiesActual = list;
    state.loadedCitiesActual = true;
  },
  setCountriesActual(state, list) {
    state.countriesActual = list;
    state.loadedCountriesActual = true;
  },
  setSettings(state, settings) {
    state.settings = settings
  },
  removeEventFromListById(state, id) {
    const index = state.events.list.data.findIndex(value => {
      return value.id === id;
    });
    state.events.list.data.splice(index, 1);
  }
};
