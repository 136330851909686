export default ({ app }, inject) => {
  // Set the function directly on the context.app object
  inject("createQueryString", queryObject => {
    let queryString = "";
    if (JSON.stringify(queryObject) !== JSON.stringify({})) {
      queryString += "?";
      let i = 0;
      for (const prop in queryObject) {
        if (i === 0) {
          queryString += prop + "=" + queryObject[prop];
        } else {
          queryString += "&" + prop + "=" + queryObject[prop];
        }
        i++;
      }
    }
    return queryString;
  });
  inject("unique", () => {
    return Math.random()
      .toString(36)
      .substr(2, 16);
  });
  inject("getDomain", () => {
    return process.env.environment === "local" ||
      process.env.environment === "staging"
      ? "https://folkradio-nuxt.firebaseapp.com"
      : "https://folkradio.hu";
  });
};
