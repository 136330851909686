<template>
  <div class="page-container">
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        class="main"
      >
        <nuxt />
      </div>
    </transition>
    <!-- <mfooter /> -->
  </div>
</template>
<script>
// const mfooter = () => import("~/components/footer.vue");

export default {
  // components: {
  //   mfooter,
  // },
  fetchOnServer: false,
  fetch() {
    this.$axios.get("radio/nowplaying", { useCache: false }).then(response => {
        this.$store.commit('setNowPlaying', response.data)      
      }).catch(e => {
        console.error('GET nowplaying error')
        console.error(e)
      }) 
  },
  mounted() {
    window.Echo.channel("radio").listen(".now-playing", e => {
      this.$store.commit('setNowPlaying', e.data)
      this.$forceUpdate()
    });

    if(!this.$store.getters['getPlaylist']['recordings'].data.length){
      this.$store.dispatch('loadRadioPlaylist')
    }
  }
}
</script>
