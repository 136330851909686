<template>
  <section class="error">
    <!-- <hero
      :title="'Hiba ' + error.statusCode"
      :events="null"
      :is-map="false"
      type="list"
    /> -->
    <div class="w-full text-center p-48">
      <h1
        v-if="error.statusCode === 404"
        class=" text-black"
      >
        {{ "A keresett oldal nem található." }}
      </h1>
      <h1
        v-else
        class="text-black"
      >
        A kérés nem értelmezhető.
      </h1>
      <nuxt-link
        to="/"
        class="my-[30px] mx-auto btn btn-red"
      >
        Tovább a főoldalra
      </nuxt-link>
    </div>
  </section>
</template>

<script>
// import hero from "~/components/hero.vue";

export default {
  components: { 
    // hero 
  },
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  layout: "default", // you can set a custom layout for the error page
  created() {
    console.log(this.error);
  }
};
</script>
