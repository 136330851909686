<template>
  <div class="page-container">
    <navbar />
    <transition
      name="fade"
      mode="out-in"
    >
      <nuxt /> 
    </transition>
    <!-- <LazyHydrate
      v-slot="{ hydrated }"
      when-visible
    > -->
    <mfooter />
    <!-- </LazyHydrate> -->
    <radioplayer
      v-if="currentSong"
      ref="radioPlayer"
      v-model="isPlaying"
      :current-song="currentSong"
      @changeRadioState="toggleStatus($event)"
    />
  </div>
</template>
<script>
// import LazyHydrate from 'vue-lazy-hydration';
const navbar = () => import("~/components/navbar.vue");
// import mfooter from '~/components/footer.vue'
// import radioplayer from '~/components/radioplayer.vue'
const radioplayer = () => import("~/components/radioplayer.vue");
const mfooter = () => import("~/components/footer.vue");

export default {
  components: {
    // LazyHydrate,
    navbar,
    mfooter,
    radioplayer
  },
  head() {
    return {
      titleTemplate: "Folknaptár | Folkrádió",
      meta: [
        {
          hid: "og:image",
          name: "og:image",
          property: "og:image",
          content: this.$getDomain() + "/img/meta_default.png"
        }
      ]
    }
  },
  data() {
    return {
      isPlaying: false,
      active: false,
      currentHero: null,
      heros: [
        // ------------------------------------------ user links
        {
          path: "/belepes",
          title: "Belépés",
          class: 'bg-login',
          breadcrumb: ['Belépés']
        }
      ]
    };
  },
  computed: {
    nowPlaying() {
      return this.$store.getters['getNowPlaying']
    },
    currentSong() {
      return this.nowPlaying.data.length
        ? this.nowPlaying.data.find(item => {
            return item.now_playing === true;
          })
        : null;
    }
  },
  watch: {
    '$route': {
      handler: function() {
        this.calculateHero()
      },
      deep: true
    }
  },
  fetchOnServer: false,
  async fetch() {
    try {
      let response = await this.$axios.get("radio/nowplaying", { useCache: false })
      this.$store.commit('setNowPlaying', response.data)    
    } catch(e) {
      console.error('GET nowplaying error')
      console.error(e)
    }  
  },
  beforeMount(){
    this.calculateHero()
  },
  mounted() {
    window.Echo.channel("radio").listen(".now-playing", e => {
      this.$store.commit('setNowPlaying', e.data)
      this.$forceUpdate()
    });

    if(!this.$store.getters['getPlaylist']['recordings'].data.length){
      this.$store.dispatch('loadRadioPlaylist')
    }
  },
  methods: {
    calculateHero(){
      for(let i = 0; i < this.heros.length; i++){
        if(this.heros[i].path === this.$route.path){
          this.currentHero = this.heros[i]
          return
        }
          
      }
      this.currentHero = null
    },
    toggleStatus() {
      this.$refs.radioPlayer.toggleStatus();
    }
  }
};
</script>